import React, { useEffect, useState } from 'react';
import './style.scss';
import { Icon, Radio, Spin, Switch } from 'antd';
import ReactHighstock from 'react-highcharts/ReactHighstock';
import preprocess from '../../containers/preprocess';
import { GET_ENTITIES } from '../../redux/actions';
import { createAction } from '../../@arivaa-react/redux/helpers/action';
import Link from '@arivaa-react/components/link';
import satoshi from '../../images/stocks/satoshi-yellow.png';
import { toCurrencyFormat } from '../../helpers/currency';
import ReturnsTable from './return-table';
import SimilarAssets from './similar-assets';
import { summaryTables } from '../../helpers/table';

const chartButtons = [
  {
    type: 'day',
    count: 1,
    text: '1d',
    title: 'View 1 Day (24h)',
    key: 'todayPercentage',
  },
  {
    type: 'week',
    count: 1,
    text: '1w',
    title: 'View 1 Week',
    key: 'weekly',
  },
  {
    type: 'month',
    count: 1,
    text: '1m',
    title: 'View 1 month',
    key: 'monthly',
  },
  {
    type: 'month',
    count: 3,
    text: '3m',
    title: 'View 3 months',
    key: 'monthly3',
  },
  {
    type: 'month',
    count: 6,
    text: '6m',
    title: 'View 6 months',
    key: 'monthly6',
  },
  {
    type: 'ytd',
    text: 'YTD',
    title: 'View year to date',
    key: 'ytd',
  },
  {
    type: 'year',
    count: 1,
    text: '1y',
    title: 'View 1 year',
    key: 'yearly',
  },
  {
    type: 'year',
    count: 3,
    text: '3y',
    title: 'View 3 years',
    key: 'yearly3',
  },

  {
    type: 'year',
    count: 5,
    text: '5y',
    title: 'View 5 years',
    key: 'yearly5',
  },
  {
    type: 'all',
    text: 'All',
    title: 'View All years',
    key: 'all',
  },
];
/**
 * @description Chart Component
 * @type component
 * @author Jasjot
 */
const Main = ({ match, symbolData, getData, data }) => {
  const [loading, setLoading] = useState(false);
  const [sats, setSats] = useState(true);
  const [log, setLog] = useState(true);
  const [percent, setPercent] = useState(false);
  const [selected, setSelected] = useState(0);
  const [fiat, setFiat] = useState(false);
  //const [range, setRange] = useState(undefined);
  const { symboltype, symbol } = match.params;
  const { hideColumns = [] } =
    summaryTables.find(({ tableKey }) => tableKey === symboltype) || {};
  let firstAxis = [];
  let secondAxis = [];
  data.forEach((item) => {
    //if ((selected === 0 && item[3]) || (selected !== 0 && !item[3])) {
      firstAxis.push([item[0], sats ? item[1] * 100000000 : item[1]]);
      secondAxis.push([item[0], item[2]]);
    //}
  });
  const config = {
    // chart : {
    //   alignTicks: true
    // },
    alignTicks: false,
    rangeSelector: {
      /*dropdown: 'responsive',*/
      selected: selected,
      buttons: chartButtons.map((item, index) => {
        item.events = {
          click: () => {
            setSelected(index);
            //   setRange(undefined);
          },
        };
        return item;
      }),
      buttonTheme: {
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        r: 4,
        style: {
          color: '#fff',
          fontWeight: 'bold',
        },
        states: {
          hover: {},
          select: {
            fill: '#039',
            style: {
              color: 'white',
            },
          },
          // disabled: { ... }
        },
      },
      inputBoxHeight: 30,
      inputBoxWidth: 120,
      inputStyle: {
        fontWeight: 'bold',
      },
      labelStyle: {
        color: '#fff',
        fontWeight: 'bold',
      },
    },
    title: {
      text: loading
        ? ''
        : `${symbolData.priceHistoryName || decodeURIComponent(symbol)}/${
            sats ? 'SATS' : 'BTC'
          } Price History`,
      align: 'left',
    },
    plotOptions: {
      series: {
        compare: percent ? 'percent' : 'values',
        // compareBase: percent ? 100 : undefined,
        showInNavigator: true,
        compareStart: true,
      },
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineColor: '#1c202e',
      labels: {
        style: {
          fontSize: 12,
          color: '#b4b7c2',
        },
      },
      /*events : {
        afterSetExtremes : function(){
          const series = this.series[0];
          if(series.processedYData && series.processedYData.length > 0 && !range){
            setRange({
              min : Math.min.apply(this,series.processedYData),
              max : Math.max.apply(this,series.processedYData)
            })
          }
        }
      },*/
    },
    yAxis: [
      {
        labels: {
          style: {
            fontSize: 12,
            color: '#b4b7c2',
          },
          formatter: percent
            ? function () {
                return (this.value > 0 ? ' + ' : '') + this.value + '%';
              }
            : undefined,
        },
        offset: 1,
        tickPixelInterval: 60,
        opposite: true,
        format: '{value:.2f}',
        ...(log
          ? {
              type: 'logarithmic',
              pointStart: 1,
            }
          : {}),

        //   ...(percent ? { tickPixelInterval: 1000}:{})
      },
      ...(fiat
        ? [
            {
              labels: {
                style: {
                  fontSize: 12,
                  color: '#b4b7c2',
                },
                formatter: percent
                  ? function () {
                      return (this.value > 0 ? ' + ' : '') + this.value + '%';
                    }
                  : undefined,
                format: '{value:.0f}',
              },
              offset: 1,
              tickPixelInterval: 60,
              tickWidth: 1.5,
              opposite: false,
              ...(log
                ? {
                    type: 'logarithmic',
                    pointStart: 1,
                  }
                : {}),

              //  ...(percent ? { tickPixelInterval: 1000}:{})
            },
          ]
        : []),
    ],
    tooltip: {
      pointFormat: percent
        ? '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>'
        : '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
      changeDecimals: 2,
      valueDecimals: 2,
      shared: true,
      split: true,
    },
    series: [
      {
        name: `${
          symbolData.priceHistoryName || decodeURIComponent(symbol)
        } Price`,
        data: firstAxis,
        color: '#f5922f',
      },

      ...(fiat
        ? [
            {
              name: `${
                symbolData.priceHistoryName || decodeURIComponent(symbol)
              } Price (USD)`,
              color: '#00db9d', // Jo ---  Color to be changed for second Axis
              data: secondAxis,

              yAxis: 1,
            },
          ]
        : []),
    ],
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await getData(symbol);
      } catch (e) {
        console.error('Error while getting data for stats table', {
          e,
        });
      }
      setLoading(false);
    })();
  }, [symbol]);
  // : symboltype === 'currency'
  // ? `${symbol}/${sats ? 'SATS' : 'BTC'}`
  console.log({ selected });
  return (
    <div className="chart page">
      <div className="container">
        <div className="content">
          <p className="title">
            <Link routeKey="landing" className="back">
              <Icon type="arrow-left" />
            </Link>
            {symbolData && (symbolData.chartName || symbolData.name)}
          </p>
          {data && data[data.length - 1] && (
            <p className="stats">
              <span>
                {sats
                  ? toCurrencyFormat(
                      Math.round(data[data.length - 1][1] * 100000000)
                    )
                  : data[data.length - 1][1].toFixed(6)}
              </span>
              &nbsp;
              {sats ? <img src={satoshi} className="satoshi" /> : '₿'}
            </p>
          )}
          <div className="render-chart">
            <Spin spinning={loading}>
              <p className="title">{config.title.text}</p>
              <div className="range-selector">
                <Radio.Group
                  onChange={(e) => setSelected(e.target.value)}
                  value={selected}
                >
                  {chartButtons.map((val, index) => (
                    <Radio.Button
                      key={index}
                      value={index}
                      style={
                        hideColumns.indexOf(val.key) !== -1
                          ? {
                              display: 'none',
                            }
                          : undefined
                      }
                    >
                      {val.text}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
              <ReactHighstock
                ref={(ref) => {
                  ref &&
                    ref.Highcharts.setOptions({
                      lang: {
                        numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
                      },
                      chart: {
                        alignTicks: true,
                      },
                    });
                }}
                config={config}
              />
            </Spin>
            {!loading && symbolData && (
              <div className="returns">
                <ReturnsTable
                  symboltype={symboltype}
                  symbolData={symbolData}
                  sats={sats}
                />
                <div className="switch">
                  <Switch
                    checked={sats}
                    onChange={(checked) => {
                      setSats(checked);
                    }}
                    size="small"
                  />
                  <span className="text">SATS</span>
                  <Switch
                    style={{
                      marginLeft: 10,
                    }}
                    checked={log}
                    onChange={(checked) => {
                      setLog(checked);
                      setPercent(false);
                    }}
                    size="small"
                  />
                  <span className="text">Log Scale</span>
                  <Switch
                    style={{
                      marginLeft: 10,
                    }}
                    checked={percent}
                    onChange={(checked) => {
                      setPercent(checked);
                      setLog(false);
                    }}
                    size="small"
                  />

                  <span className="text">Percent</span>
                  <Switch
                    style={{
                      marginLeft: 10,
                    }}
                    checked={fiat}
                    onChange={(checked) => {
                      setFiat(checked);
                      // setLog(!checked)
                    }}
                    size="small"
                  />

                  <span className="text">Fiat</span>
                </div>
              </div>
            )}
          </div>
          {!loading && symbolData && (
            <>
              {/*<ReturnsTable symbolData={symbolData} sats={sats} />*/}
              <div className="similar-assets">
                <p className="label">Similar Assets</p>
                <SimilarAssets
                  group={symboltype}
                  symbolData={symbolData}
                  sats={sats}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getData: (entityId) => {
      const action = createAction(GET_ENTITIES, {
        entityName: 'bitcoin-stats-v2/series',
        entityId,
      });
      action.type = action.type + '_timeline';
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = ({ timeline, bitcoinStats }, { match }) => {
  const data = timeline[`bitcoin-stats-v2/series/${match.params.symbol}`];
  return {
    data: (data && data.data) || [],
    symbolData: (data && data.symbolData) || {},
  };
};

Main.displayName = 'Chart';
Main.url = 'chart/:symboltype/:symbol';
//Pre process the container with Redux Plugins
export default preprocess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
