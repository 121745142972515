import React from 'react';
import './style.scss';
import insta from 'images/stocks/insta.png';
import facbook from 'images/stocks/fb.png';
import twitter from 'images/stocks/tw.png';

import sponsor from 'images/stocks/logo.png';
import fastbitcoins from '../../images/stocks/fastbitcoins.png';

/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = () => {
  return (
    <div className="footer">
      <div className="container flex">
        <div className="left flex">
          {/*<p className="label">Supported by Mimesis Capital</p>*/}
           {/*<a href={'https://www.mimesiscapital.com/'} target="_blank">
            <img src={sponsor} className="sponsor" />
          </a>
         <a
            href={'https://www.mimesiscapital.com/'}
            target="_blank"
            className="fast-bitcoins"
          >
            <span className="text">Buy with</span>
            <img src={fastbitcoins} />
          </a> */}
          {/* <p>Run by a pleb, donations accepted in Bitcoin only.</p>
          <a
            href={window.app.ui ? window.app.ui.BITCOIN_ADDRESS : null}
            target="_blank"
          >
            <Button
              // onClick={async () => {
              //   try {
              //     await navigator.clipboard.writeText(
              //       window.app.ui.BITCOIN_ADDRESS
              //     );
              //     message.success('Bitcoin Address copied to your clipboard');
              //   } catch (err) {
              //     console.error('Failed to copy: ', err);
              //   }
              // }}
              className="primary-btn"
            >
              Donate with bitcoin
            </Button>
          </a> */}
        </div>
        <div className="right flex">
          <p className="label">Follow Us: </p>
          <div className="social">
            <ul>
              <li>
                <a href={window.app.ui.INSTAGRAM_LINK} target="_blank">
                  <img src={insta} align="Instagram" />
                </a>
              </li>
              <li>
                <a href={window.app.ui.FACEBOOK_LINK} target="_blank">
                  <img src={facbook} align="Facebook" />
                </a>
              </li>
              <li>
                <a href={window.app.ui.TWITTER_LINK} target="_blank">
                  <img src={twitter} align="Twitter" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Main.displayName = 'Footer';
//Pre process the container with Redux Plugins
export default Main;
