import React from 'react';
import './style.scss';
import { Icon, Popover } from 'antd';
import Link from '@arivaa-react/components/link';
import logo from 'images/stocks/logo-new.png';
import sponsor from 'images/stocks/logo.png';
import fastbitcoins from 'images/stocks/fastbitcoins.png';
import preprocess from '../../preprocess';
import {
  abbreviateCurrency,
  toCurrencyFormat,
  toDecimal,
} from '../../../helpers/currency';

const Main = ({ marketCap, volume, dominance, price }) => {
  /**
   * @description Header Component
   * @type component
   * @author Jasjot
   */

  const navigation = (
    <div className="navigation">
      <ul>
        <li key="landing">
          <Link routeKey="landing">Home</Link>
        </li>
        <li key="parity">
          <Link routeKey="parity">Parity</Link>
        </li>
        <li key="publications">
          <Link routeKey="publications">Publications</Link>
        </li>
        <li key="about">
          <Link routeKey="about">About</Link>
        </li>
        <li key="shop">
          <a href="https://pricedinbitcoin21.myshopify.com/" target="_blank">
            Shop
          </a>
        </li>
        {/* <li>
        <a>Subscribe</a>
      </li> */}
         {/*<li key="sponsor">
         <Button
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(
                window.app.ui.BITCOIN_ADDRESS
              );
              message.success('Bitcoin Address copied to your clipboard');
            } catch (err) {
              console.error('Failed to copy: ', err);
            }
          }}
          className="primary-btn"
        >
          Donate with bitcoin
        </Button>
          <a href={'https://www.mimesiscapital.com/'} target="_blank">
            <img src={sponsor} className="sponsor" />
          </a>
          
        </li>
        */}
        {/*<li key="fast-bitcoins" className="fast-bitcoins">
          <a href={'https://www.mimesiscapital.com/'} target="_blank">
            <span className="text">Buy with</span>
            <img src={fastbitcoins} />
          </a>
        </li>*/}
      </ul>
    </div>
  );
  return (
    <div className="header">
      <div className="top flex">
        <div className="container flex">
          <div className="left">
            <div className="numbers">
              <ul>
                <li>
                  <p>
                    <span className="label">BTCUSD : </span>
                    <span className="value">
                      $ {toCurrencyFormat(Math.round(toDecimal(price)))}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span className="label">Market Cap : </span>
                    <span className="value">
                      $ {abbreviateCurrency(marketCap)}
                    </span>
                  </p>
                </li>
                <li>
                  <span className="label">24h Vol : </span>
                  <span className="value">$ {abbreviateCurrency(volume)}</span>
                </li>
                {/* <li>
                  <span className="label">Dominance : </span>
                  <span className="value">
                    BTC {toDecimal(dominance * 100)} %{' '}
                  </span>
                </li> */}
              </ul>
            </div>
          </div>
          {/*<div className="right">
            <div className="social">
              <ul>
                <li>
                  <a href={window.app.ui.INSTAGRAM_LINK} target="_blank">
                    <img src={insta} align="Instagram" />
                  </a>
                </li>
                <li>
                  <a href={window.app.ui.FACEBOOK_LINK} target="_blank">
                    <img src={facbook} align="Facebook" />
                  </a>
                </li>
                <li>
                  <a href={window.app.ui.TWITTER_LINK} target="_blank">
                    <img src={twitter} align="Twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>*/}
        </div>
      </div>
      <div className="bottom flex">
        <div className="container flex">
          <div className="left">
            <Link routeKey="landing">
              <div className="logo">
                {/* <span className="text">Priced In</span>*/}
                <img src={logo} align="logo" />
              </div>
            </Link>
          </div>
          <div className="right">
            {navigation}
            <div className="mobile-navigation">
              <Popover
                content={navigation}
                placement="bottomRight"
                trigger="click"
              >
                <Icon type="menu" />
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Bind State to props
 * @returns {{Object}}
 * @param state
 */
const mapStateToProps = ({ bitcoinStats }) => {
  return {
    ...(bitcoinStats || {}),
  };
};
Main.displayName = 'Public-Header';
//Pre process the container with Redux Plugins
export default preprocess(Main, {
  connect: [mapStateToProps, null],
  localize: true,
});
